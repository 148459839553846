import axios from 'axios';

const { href: baseURL } = new URL('/api/v1/send/', process.env.SENDER_HOST);
const token = process.env.SENDER_TOKEN;
const app = document.documentElement.dataset.appName;
const locale = document.documentElement.lang;

const sender = axios.create({
  baseURL,
  auth: {
    username: process.env.SENDER_USERNAME,
    password: process.env.SENDER_PASSWORD,
  },
});

export default {
  send: {
    contact: (form) => {
      return sender.post('message', { ...form, locale, token, app }, {});
    },
    contactCompany: (form) => {
      return sender.post('message-company', { ...form, locale, token, app }, {});
    },
    giftCard: (form) => {
      return sender.post('gift-card', { ...form, locale, token, app });
    },
    contactAgile: (form) => {
      var contact = {};
      contact.email = form.email;
      contact.first_name = form.name;
      contact.title = "lead";
      contact.website = "http://ardi.one";
      contact.tags = "Lead, Likely Buyer";
      _agile.create_contact(contact);
      return true;
    }
  },
};

import { keys } from 'lodash';
import sender from './sender-api';
import backend from './backend-api';
import toast from '../plugins/toast';
import { format } from 'date-fns';

export default (payment_link, game_name) => ({
  showForm: false,
  form: {
    game: '',
    email: '',
    greetings: '',
    date: '',
  },
  init() {
    if (game_name) {
      this.form.game = game_name;
    }
  },
  loading: false,
  get disabled() {
    return ['game', 'email'].some((key) => this.form[key] === '');
  },
  button: {
    ':class'() {
      return {
        'is-loading': this.loading,
      };
    },
    ':disabled'() {
      return this.disabled;
    },
  },
  async send() {
    this.form.date = format(new Date(), 'dd/MM/yyyy, H:mm:ss');
    this.loading = true;
    try {
      await sender.send.giftCard(this.form);
    } catch (error) {
      keys(error.response.data.errors).forEach((key) => {
        toast.error(error.response.data.errors[key][0]);
      });
      return;
    } finally {
      this.loading = false;
    }

    try {
      if (process.env.NODE_ENV === 'production') {
        await backend.forms.giftCard(this.form);
      }
      window.location = payment_link;
    } catch (error) {
      console.error(error);
    }
  },
});

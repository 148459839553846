import inView from 'in-view';

const headerLogo = document.querySelector('.header-logo-full');

const firstScreenLogoClassname = '.ardi .is_homepage .first-screen-logo';

const hide = () => {
  headerLogo.classList.remove('visible');
  headerLogo.classList.add('hidden');
};

const show = () => {
  headerLogo.classList.remove('hidden');
  headerLogo.classList.add('visible');
};

export default () => ({
  logo: {
    'x-init'() {
      inView.is(document.querySelector(firstScreenLogoClassname)) ? hide() : show();
    },
    '@scroll.window'() {
      inView(firstScreenLogoClassname).on('enter', hide).on('exit', show);
    },
  },
});

export default () => ({
  closeButton: {
    '@click'() {
      this.close();
    },
    ':class'() {
      return {
        'is-active': this.visibility,
      };
    },
  },
});

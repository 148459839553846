import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';

import components from './components';
import forms from './forms';
import layouts from './layouts';
import store from './store';

import './plugins';

window.Alpine = Alpine;

Alpine.plugin(collapse);

const alpineInstances = {
  ...components,
  ...forms,
  ...layouts,
};

document.addEventListener('alpine:init', () => {
  Object.keys(store).forEach((name) => {
    Alpine.store(name, store[name]);
  });
  Object.keys(alpineInstances).forEach((key) => {
    Alpine.data(key, alpineInstances[key]);
  });
});

Alpine.start();

export default () => ({
  visibility: false,
  title: {
    '@click'() {
      this.visibility = !this.visibility;
    },
    ':class'() {
      return {
        'is-active': this.visibility,
      };
    },
  },
  answer: {
    ':class'() {
      return {
        'is-active': this.visibility,
      };
    },
    'x-show'() {
      return this.visibility;
    },
  },
});

import { parse, getUnixTime, intervalToDuration, differenceInSeconds } from 'date-fns';

const dayLetter = document.documentElement.lang === 'ru' ? 'д' : 'd';
const monthLetter = document.documentElement.lang === 'ru' ? 'м' : 'm';

export default (end_date, end_time, appName) => ({
  isActive: true,
  appName: null,
  promoId: null,
  storageKey: null,
  endAt: null,
  countdown: `0${dayLetter} 00:00:00`,
  init() {
    this.endAt = parse(`${end_date} ${end_time}`, 'dd-MM-yyyy H:mm', new Date());
    this.promoId = getUnixTime(this.endAt);
    this.appName = appName;
    this.storageKey = `${this.appName}-promo-${this.promoId}-disabled`;
    if (window.localStorage.getItem(this.storageKey)) {
      this.isActive = false;
    } else {
      this.setCountdown();
      setInterval(() => {
        this.setCountdown();
      }, 1000);
    }
    this.$watch('isActive', () => {
      Alpine.store('share').headerHeight = document.getElementById('header').offsetHeight;
    });
  },
  setCountdown() {
    if (differenceInSeconds(Date.now(), this.endAt) >= 0) {
      this.isActive = false;
      return;
    }
    const duration = intervalToDuration({ start: Date.now(), end: this.endAt });
    const months = duration.months ? `${duration.months}${monthLetter} ` : '';
    const days = duration.days ? `${duration.days}${dayLetter} ` : '';
    const hours = duration.hours <= 9 ? `0${duration.hours}` : duration.hours;
    const minutes = duration.minutes <= 9 ? `0${duration.minutes}` : duration.minutes;
    const seconds = duration.seconds <= 9 ? `0${duration.seconds}` : duration.seconds;
    this.countdown = `${months}${days}${hours}:${minutes}:${seconds}`;
  },
  hide() {
    this.isActive = false;
    window.localStorage.setItem(this.storageKey, '1');
  },
  close: {
    '@click.prevent'() {
      this.hide();
    },
  },
});

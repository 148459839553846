export default {
  visibility: false,
  open() {
    document.body.classList.add('overflow-hidden');
    this.visibility = true;
  },
  close() {
    document.body.classList.remove('overflow-hidden');
    this.visibility = false;
  },
};

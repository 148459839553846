// Sliders
import Splide from '@splidejs/splide';

const carousels = document.querySelectorAll('.reviews-carousel.splide');
if (carousels.length) {
  carousels.forEach((carousel) => {
    new Splide(carousel, {
      autoplay: 8000,
      arrows: false,
      type: 'loop',
    }).mount();
  });
}

export default () => ({
  visibility: false,
  trigger: {
    '@click'() {
      this.visibility = !this.visibility;
    },
    '@click.outside'() {
      this.visibility = false;
    },
    '@keyup.escape.window'() {
      this.visibility = false;
    },
  },
  list: {
    ':style'() {
      return {
        display: this.visibility ? 'block' : 'none',
      };
    },
  },
});

import { keys } from 'lodash';
import sender from './sender-api';
import backend from './backend-api';
// import toast from '../plugins/toast';

export default () => ({
  init() {
    this.$watch('status', () => {
      if (this.status.type === 'success') {
        setTimeout(() => {
          this.status.type = '';
          this.status.text = '';
        }, 12000);
      }
    });
  },
  form: {
    name: '',
    email: '',
    message: '',
  },
  status: {
    type: '',
    text: '',
  },
  loading: false,
  get disabled() {
    return keys(this.form).some((key) => this.form[key] === '');
  },
  message: {
    ['x-show']() {
      return this.status.text !== '';
    },
    ['x-html']() {
      return this.status.text;
    },
    [':class']() {
      return [`is-${this.status.type}`];
    },
  },
  button: {
    ':class'() {
      return {
        'is-loading': this.loading,
      };
    },
    ':disabled'() {
      return this.disabled;
    },
  },
  async send() {
    this.loading = true;
    try {
      const { data } = await sender.send.contactCompany(this.form);
      // toast.success(data.message);
      this.status.type = 'success';
      this.status.text = data.message;
    } catch (error) {
      /*keys(error.response.data.errors).forEach((key) => {
        toast.error(error.response.data.errors[key][0]);
      });*/
      this.status.type = 'danger';
      this.status.text = keys(error.response.data.errors)
        .map((name) => error.response.data.errors[name][0])
        .join('\n');
      return;
    } finally {
      this.loading = false;
    }
    try {
      if (process.env.NODE_ENV === 'production') {
        await backend.forms.contactCompany(this.form);
      }
      keys(this.form).forEach((key) => {
        this.form[key] = '';
      });
    } catch (error) {
      console.error(error);
    }
  },
});

import { Notyf } from 'notyf';

const notyf = new Notyf({
  duration: 6000,
  position: {
    x: 'right',
    y: 'bottom',
  },
});

export default {
  success(message) {
    notyf.success(message);
  },
  error(message) {
    notyf.error(message);
  },
};

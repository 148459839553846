import axios from 'axios';
import { format } from 'date-fns';

const name = process.env.APP_NAME.toUpperCase();
const host = process.env[`${name}_HOST`];
const token = process.env[`${name}_FORM_TOKEN`];
const language = document.documentElement.lang;

const backend = axios.create({
  baseURL: host,
  headers: {
    'Content-Type': 'application/json',
    'Cockpit-Token': token,
  },
});

export default {
  forms: {
    contact: (form) => {
      return backend.post('api/forms/submit/contact', {
        form: { ...form, language, date: format(new Date(), 'dd/MM/yyyy, H:mm:ss') },
      });
    },
    contactCompany: (form) => {
      return backend.post('api/forms/submit/contact_company', {
        form: { ...form, language, date: format(new Date(), 'dd/MM/yyyy, H:mm:ss') },
      });
    },
    giftCard: (form) => {
      return backend.post('api/forms/submit/giftcard', {
        form: { ...form, language },
      });
    },
  },
};

export default () => ({
  click() {
    if (window.innerWidth > 992 && this.$refs.card.classList.contains('is-realized')) {
      const button = this.$refs.card.querySelector('a');
      if (button) {
        this.$refs.card.querySelector('a').click();
      }
    }
  },
});

// Sliders
import Splide from '@splidejs/splide';

const carousels = document.querySelectorAll('.games-carousel');
if (carousels.length) {
  carousels.forEach((carousel) => {
    const slider = new Splide(carousel, {
      autoplay: 8000,
      arrows: false,
      perPage: 3,
      type: 'loop',
      breakpoints: {
        576: {
          destroy: true,
        },
        768: {
          perPage: 1,
        },
        992: {
          perPage: 2,
        },
      },
    });
    slider.mount();

    if (slider.length < 4) {
      slider.root.classList.add('is-destroyed');
      slider.destroy();
    }
  });
}

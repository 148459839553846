import Equalizer from 'equalizer';
import { debounce } from 'lodash';

const targets = [
  '.game-card-title',
  '.game-card-description',
  '.game-card-languages',
  '.game-card-price',
  '.game-card-coming-soon',
  '.game-card-features',
];

window.addEventListener('load', () => {
  targets.forEach((target) => {
    if (document.querySelectorAll(target).length && window.innerWidth > 768) {
      Equalizer(target).align();
      window.addEventListener(
        'resize',
        debounce(() => {
          Equalizer(target).align();
        }, 200),
      );
    }
  });
});

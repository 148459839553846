export default () => ({
  slide: {
    ':style'() {
      return {
        'min-height': `calc(100vh - ${
          Alpine.store('share').headerHeight + Alpine.store('share').salesPointsHeight
        }px)`,
      };
    },
  },
});

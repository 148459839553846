export default () => ({
  init() {
    this.storeHeight();
  },
  storeHeight() {
    Alpine.store('share').salesPointsHeight = this.$el.offsetHeight;
  },
  section: {
    '@resize.window.debounce.200ms'() {
      this.storeHeight();
    },
  },
});

const { debounce } = require('lodash');

export default () => ({
  active: false,
  up() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  },
  scroll: debounce(function () {
    this.active = window.scrollY > 1000;
  }, 200),
});

// Sliders
import Splide from '@splidejs/splide';

const sliders = document.querySelectorAll('.slider.splide');
if (sliders.length) {
  sliders.forEach((slide) => {
    new Splide(slide, {
      autoplay: 8000,
      arrows: false,
      type: 'loop',
      lazyLoad: 'nearby',
    }).mount();
  });
}

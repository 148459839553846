import drawer from './drawer';
import faq from './faq';
import firstScreen from './first-screen';
import gameCard from './game-card';
import header from './header';
import promo from './promo';
import slide from './slide';
import salesPoints from './sales-points';
import upTop from './up-top';

export default { drawer, faq, firstScreen, gameCard, header, promo, slide, salesPoints, upTop };
